import React, {useEffect} from "react";
import PropTypes from "prop-types";
import Header from "../partials/header/header.component";
import Footer from "../partials/footer/footer.component";
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import Cookies from "../partials/cookies/Cookies.component";

const Layout = ({ children, whiteLayout, isHomePage, isSearch }) => {
    useEffect(() => {
        setTimeout(() => {
            document.querySelector("#layout").style.display = "block";
        }, 100);
    });

    return (
        <div style={{display: 'none'}} id="layout">
             <Header whiteLayout={whiteLayout} isSearch={isSearch}/>
             <div id="body-overlay"/>
             {children}
             <Cookies isDefaultLayout={false}/>
             <Footer isHomePage={isHomePage}/>
         </div>
    )
}

Layout.defaultProps = {
    whiteLayout: false,
    isHomePage: false,
    isSearch: false,
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    whiteLayout: PropTypes.bool.isRequired,
    isHomePage: PropTypes.bool.isRequired,
    isSearch: PropTypes.bool.isRequired,
}

export default Layout
