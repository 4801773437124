import React from "react"
import { Link } from "gatsby"
import Assets from "../../assets/index";

const IconX = Assets.Icons.IconX;

class Cookies extends React.Component {
    cookieName = 'complianceCookie';

    constructor(props) {
        super(props);

        this.state = {
            showMe: false,
        };

        this.ROUTES = props.routes;

        this.isDefaultLayout = props.isDefaultLayout != null ? props.isDefaultLayout : true;
    }

    componentDidMount() {
        this.setState({
            showMe: this.isNotActiveCookie(this.cookieName),
        });
    }

    closeFooterCookieInfoBar() {
        if (this.isNotActiveCookie(this.cookieName)) {
            document.cookie = this.cookieName + "=" + true + ";expires=Fri, 31 Dec 9999 23:59:59 GMT;path=/";

            this.setState({
                showMe: false,
            });
        }
    }

    isNotActiveCookie(name) {
        let cookies = document.cookie.split(';'),
            timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        name = name + "=";

        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i];

            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1, cookie.length);
            }

            if (cookie.indexOf(name) === 0) {
                return false;
            }
        }

        if (!timezone.includes('Europe')) {
            return false;
        }

        return true;
    }

    renderLayout() {
      const { t } = this.props;

        return (
            <div>
                { this.state.showMe &&
                    <div className="d-flex justify-content-between cookies-sticky-bottom py-2 px-4">
                        <div className="d-flex">
                            <p className="cookies-text mb-0">
                                { t('cookies.description') }
                                <Link to={ this.ROUTES.privacy_policy_and_terms_of_use.url } target="_blank" className="ml-1" rel="noopener noreferrer">{ t('cookies.read_more') }</Link>
                            </p>
                        </div>

                        <button className="custom-cookies-button" onClick={ this.closeFooterCookieInfoBar.bind(this) }>✖</button>
                    </div>
                }
            </div>
        )
    }

  renderLayoutCustom() {
    const { t } = this.props;

    return (
      <div>
        { this.state.showMe &&
        <div className="d-flex justify-content-between cookies-sticky-bottom">
          <div className="d-flex">
            <p className="cookies-text mb-0">
              { t('cookies.description') }
              <Link to={ this.ROUTES.privacy_policy_and_terms_of_use.url } target="_blank" className="ml-1" rel="noopener noreferrer">{ t('cookies.read_more') }</Link>
            </p>
          </div>

          <button className="cookies-button" onClick={ this.closeFooterCookieInfoBar.bind(this) }>
            <IconX className="cookies-button-icon"/>
          </button>
        </div>
        }
      </div>
    )
  }

  render() {
    return this.isDefaultLayout ? this.renderLayout() : this.renderLayoutCustom();
  }
}

const CookiesWithoutTrans = Cookies;

export {
  CookiesWithoutTrans,
}

export default Cookies
