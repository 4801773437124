import React from "react";

const SearchIcon = ( props ) => {
    return (
        <div className={props.className}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 1"
                viewBox="0 0 23.73 23.9"
                height="21"
                width="21"
                className={props.className}
            >
                <path
                    fill={props.fill}
                    d="M23.73 22.8l-6.1-6.1a10.16 10.16 0 00-.5-13.7A10 10 0 0010 0a9.6 9.6 0 00-7.07 3 10.18 10.18 0 000 14.3A9.78 9.78 0 0010 20.2a10.31 10.31 0 006.61-2.4l6.1 6.1zm-19.8-6.6a8.63 8.63 0 010-12.1A8.58 8.58 0 0110 1.6a8.84 8.84 0 016.11 2.5 8.65 8.65 0 010 12.1 8.75 8.75 0 01-12.18 0z"
                ></path>
            </svg>
        </div>
    );
}

export default SearchIcon;
