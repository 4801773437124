import {Helmet} from "react-helmet";
import React from "react";
import PropTypes from "prop-types";

function SEO({data}) {
    if (!data) {
        return;
    }

    let styleLInk = null;
    if (process.env.NODE_ENV === 'development' && data.style_link) {
        styleLInk = (<link href={ data.style_link } rel='stylesheet'/>);
    }

    return (
        <Helmet
            htmlAttributes={{lang: data.meta.lang}}
            title={data.meta.title}
            meta={[
                data.meta.description,
                data.meta.image,
                data.meta.robots,
                data.meta.og_site_name,
                data.meta.twitter_card,
                data.meta.og_url,
                data.meta.og_title,
                data.meta.og_type,
                data.meta.og_description,
                data.meta.og_image,
                data.meta.article_published_at,
                data.meta.article_modified_at,
                data.meta.article_author,
            ]}
            script={[
                data.structured_data,
            ]}
        >
            {
                process.env.NODE_ENV !== 'development' && data.styles && Array.isArray(data.styles) && data.styles.map(function(item, index){
                    return (<style type="text/css">{ item.style }</style>);
                })
            }

            { styleLInk }
            <link rel="canonical" href={data.canonical}/>
        </Helmet>
    )
}

SEO.defaultProps = {
    data: {},
};
SEO.propTypes = {
    data: PropTypes.object,
};

export default SEO
