import React from "react";
import ROUTES from "../../configs/route_final";
import Container from "react-bootstrap/Container";
import {Link} from "gatsby";
import Row from "react-bootstrap/Row";
import PropTypes from "prop-types";

const Footer = (props) => {
    return (
        <footer className={props.isHomePage ? 'footer-homepage' : ''}>
            <Container>
                <Row className="footer-background justify-content-center">
                    <div className="col-12 col-lg-8">
                        <div className="row margin-bottom-40">
                            <div className="col-12 col-md-4 footer-icon-wrapper">
                                <img
                                    data-src={ROUTES.home.icon_white}
                                    className="footer-icon lazyload d-md-none"
                                    alt={ROUTES.home.icon_alt}
                                />
                                <img
                                    data-src={ROUTES.home.icon_footer}
                                    className="footer-icon lazyload d-none d-md-block"
                                    alt={ROUTES.home.icon_alt}
                                />
                            </div>
                            <div className="col-12 col-md-8">
                                <p className="footer-description">
                                    If you're looking to get the best out of web hosting — you've come to the right place. In Emit.Reviews, I'll help you pick the best provider at the right price, and give you extra tips to kickstart your new project.
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-4 footer-category-wrapper">
                                <p className="footer-category">Special Offers</p>
                                <ul className="footer-list">
                                    <li>
                                        <Link to={ROUTES.discounts.path} className="footer-link">
                                            {ROUTES.discounts.menu_title}
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 col-md-4 footer-category-wrapper">
                                <p className="footer-category">Categories</p>
                                <ul className="footer-list">
                                    <li>
                                        <Link to={ROUTES.best.path} className="footer-link">
                                            {ROUTES.best.menu_title}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={ROUTES.reviews.path} className="footer-link">
                                            {ROUTES.reviews.menu_title}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={ROUTES.tutorials.path} className="footer-link">
                                            {ROUTES.tutorials.menu_title}
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 col-md-4 footer-category-wrapper">
                                <p className="footer-category">Legal</p>
                                <ul className="footer-list">
                                    <li>
                                        <Link to={ROUTES.advertisement_disclosure.path} className="footer-link">
                                            {ROUTES.advertisement_disclosure.menu_title}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={ROUTES.privacy_policy_and_terms_of_use.path} className="footer-link">
                                            {ROUTES.privacy_policy_and_terms_of_use.menu_title}
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Row>
            </Container>
            <Container>
                <Row className="footer-background-white justify-content-center">
                    <div className="col-12 col-lg-8">
                        <div className="row">
                            <div className="col-12">
                                <p className="footer-rights">
                                    © EmitReviews. All rights reserved
                                </p>
                            </div>
                        </div>
                    </div>
                </Row>
            </Container>
        </footer>
    );
};

Footer.defaultProps = {
    isHomePage: false,
};

Footer.propTypes = {
    isHomePage: PropTypes.bool,
}

export default Footer
