import React from "react";
import {Cookies as CookiesCore} from "../../gatsby-core/cookies";
import PropTypes from "prop-types";

class Cookies extends CookiesCore {
    render() {
        return (
            <div>
                {this.state.showMe &&
                <div className="d-flex justify-content-between cookies-wrapper">
                    <div className="cookies-container container">
                        <p className="cookies-text d-lg-inline">
                            Just a heads up — this website uses cookies to enhance your user experience. If you accept, just carry on browsing.
                        </p>
                        <button className="cookies-button" onClick={this.closeFooterCookieInfoBar.bind(this)}>
                            Agree
                        </button>
                    </div>
                </div>
                }
            </div>
        )
    }
}

Cookies.defaultProps = {
    isDefaultLayout: false,
};

Cookies.propTypes = {
    isDefaultLayout: PropTypes.bool.isRequired
};

export default Cookies
