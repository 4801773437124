module.exports = {
    home: {
        wordpress_id: 12,
        title: "Home",
        path: "/",
        seo_canonical: "/",
        icon: 'https://images.emit.reviews/horizontal-color.svg',
        icon_white_text: 'https://images.emit.reviews/horizontal-color-white-text.svg',
        icon_white: 'https://images.emit.reviews/icon-white.svg',
        icon_footer: 'https://images.emit.reviews/horizontal-white.svg',
        icon_alt: "Logo",
        icon_width: "124",
        description: "emitreviews.com",
        seo_include_in_sitemap: null,
        seo_sitemap_priority: 1,
    },
    reviews: {
        wordpress_id: 46,
        title: "Reviews",
        menu_title: "Reviews",
        description: "Reviews.",
        path: "/reviews/",
        seo_canonical: "/reviews/",
        seo_include_in_sitemap: null,
        seo_sitemap_priority: 1,
    },
    best: {
        wordpress_id: 44,
        title: "Best",
        menu_title: "Best",
        description: "Best",
        path: "/best/",
        seo_canonical: "/best/",
        seo_include_in_sitemap: null,
        seo_sitemap_priority: 1,
    },
    tutorials: {
        wordpress_id: 42,
        title: "Tutorials",
        menu_title: "Tutorials",
        description: "Tutorials.",
        path: "/tutorials/",
        seo_canonical: "/tutorials/",
        seo_include_in_sitemap: null,
        seo_sitemap_priority: 1,
    },
    discounts: {
        wordpress_id: 52,
        title: "Discounts",
        menu_title: "Discounts",
        description: "Discounts.",
        path: "/discounts/",
        seo_canonical: "/discounts/",
        seo_include_in_sitemap: null,
        seo_sitemap_priority: 1,
    },
    privacy_policy_and_terms_of_use: {
        wordpress_id: 8,
        title: "Privacy policy",
        menu_title: "Privacy policy",
        description: "Privacy policy",
        path: "/terms-of-use-and-privacy-policy/",
        seo_canonical: "/terms-of-use-and-privacy-policy/",
        seo_include_in_sitemap: null,
        seo_sitemap_priority: 1,
    },
    advertisement_disclosure: {
        wordpress_id: 6,
        title: "Advertisement disclosure",
        menu_title: "Advertisement disclosure",
        description: "Advertisement disclosure",
        path: "/advertisment-disclosure/",
        seo_canonical: "/advertisment-disclosure/",
        seo_include_in_sitemap: null,
        seo_sitemap_priority: 1,
    },
    page_not_found: {
        wordpress_id: 0,
        title: "404: Not found",
        menu_title: "404: Not found",
        description: "404: Not found",
        path: "/404/",
        seo_canonical: "/404/",
        seo_include_in_sitemap: null,
        seo_sitemap_priority: 1,
    },
    search_page: {
        wordpress_id: 0,
        title: "Search",
        menu_title: "Search",
        description: "Search",
        path: "/search-page/",
        seo_canonical: "/search-page/",
        seo_include_in_sitemap: null,
        seo_sitemap_priority: 1,
    },
};
