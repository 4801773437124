import React from "react";

const Backdrop = props => (
    <div className={props.className}
         onClick={props.click}
         onKeyDown={props.click}
         role="button"
         tabIndex={0}
         aria-label="bakcdrop"/>
);

export default Backdrop;
