import React from "react";
import PropTypes from "prop-types";
import {Link} from "gatsby";
import {getImage} from "../../../helpers";

const Post = (props) => {
    let imageLink = getImage(props.data.node.featured_media_srcset, "category-image",  props.data.node.title, );

    return (
        <div className="">
            <Link to={props.data.node.path} className="category-link">
                <div className="category-wrapper">
                    <div className="category-image-wrapper">
                        {imageLink}
                    </div>
                    <div className="category-text-wrapper">
                        <p className="category-date">{props.data.node.small_date}</p>
                        <p className="category-title">{props.data.node.title}</p>
                        <p className="category-text">{props.data.node.excerpt.substr(0, 140)}...</p>
                    </div>
                </div>
            </Link>
        </div>
    );
};

Post.propTypes = {
    data: PropTypes.shape({
        node: PropTypes.shape({path: PropTypes.string.isRequired,
            small_date: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            excerpt: PropTypes.string.isRequired
        }).isRequired
    }).isRequired
};

export default Post
