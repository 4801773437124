import React from "react";

const IconX = ( props ) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 17.88 17.88"
      height={props.height}
      width={props.width}
      className={props.className}
    >
      <path
        fill={props.color}
        d="M10.05 9l7.88 7.88L16.87 18 9 10.07 1.11 18 .05 16.89 7.93 9 .05 1.13 1.11.07 9 8 16.87.07l1.06 1.06z"
        transform="translate(-.05 -.07)"
      ></path>
    </svg>
  );
}

export default IconX;
