import React from "react";

const ArrowTop = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67 67" fill={props.fill} className={props.className}>
            <g data-name="Layer 2">
                <g data-name="Layer 1">
                    <path d="M33.5 0A33.51 33.51 0 1067 33.5 33.54 33.54 0 0033.5 0zm0 65a31.49 31.49 0 1125.56-13.08A31.53 31.53 0 0133.5 65z"></path>
                    <path d="M51.29 41.71L34 24.41l-17.29 17.3-1.42-1.42L34 21.59l18.71 18.7z"></path>
                </g>
            </g>
        </svg>
    );
}

export default ArrowTop;
