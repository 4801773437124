import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import ROUTES from "../../configs/route_final";
import {navigate, prefetchPathname, StaticQuery, graphql} from "gatsby";
import PropTypes from "prop-types";
import SearchBar from "../search/searchBar.component";

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isMobile: false,
            isTablet: false,
            prev: 0,
            searchSideDrawerOpen : false,
            searchNavbarOpen: false,
        };

        this.whiteLayout = props.whiteLayout;
        this.isSearch = props.isSearch;
        this.currentUrl = '';
        this.onMouseMoveBodyEvent = this.onMouseMoveBody.bind(this);
        this.scrollFunction = this.handleScroll.bind(this);
    };

    componentDidMount() {
        this.setIsMobile();

        document.querySelector("body").classList.remove("overflow-hidden");
        this.currentUrl = '/' + window.location.pathname.split('/')[1] + '/';
        document.body.addEventListener("mousemove", this.onMouseMoveBodyEvent);
        document.body.addEventListener("scroll", this.onMouseMoveBodyEvent);
        window.addEventListener("scroll", this.scrollFunction);
    };

    componentWillUnmount() {
        document.body.removeEventListener("mousemove", this.onMouseMoveBodyEvent);
        document.body.removeEventListener("scroll", this.onMouseMoveBodyEvent);
        window.removeEventListener("scroll", this.scrollFunction);
    };

    onMouseMoveBody() {
        prefetchPathname(ROUTES.home.path);
        prefetchPathname(ROUTES.reviews.path);
        prefetchPathname(ROUTES.best.path);
        prefetchPathname(ROUTES.tutorials.path);
        prefetchPathname(ROUTES.discounts.path);

        document.body.removeEventListener("mousemove", this.onMouseMoveBodyEvent);
        document.body.removeEventListener("scroll", this.onMouseMoveBodyEvent);
    };

    setIsMobile() {
        this.setState({
            isTablet: window.matchMedia("only screen and (max-width: 992px)").matches,
            isMobile: window.matchMedia("only screen and (max-width: 767px)").matches,
        });
    };

    handleScroll(event) {
        if ((this.state.isMobile || this.state.isTablet)) {
            const window = event.currentTarget,
                navbar = document.querySelector(".navbar"),
                searchButton = document.querySelector(".search-menu-bar-button");

            if (this.state.prev > window.scrollY) {
                navbar.classList.remove("navbar-hidden");
                if(searchButton) {
                    searchButton.classList.remove("d-none");
                }
            } else if (this.state.prev < window.scrollY) {
                navbar.classList.add("navbar-hidden");
                if(searchButton) {
                    searchButton.classList.add("d-none");
                }
            }
            this.setState({prev: window.scrollY});
        }
    };

    checkIfCurrentPage(url) {
        return url === this.currentUrl;
    };

    render() {
        let searchIconColor = "#373B4B",
            className = "",
            bottomNavigationClassName = 'bottom-navigation-wrapper';

        if (this.state.isMobile) {
            if (this.whiteLayout && this.state.prev > 0) {
                searchIconColor = "#fff";
                className = "navbar-dark";
            } else if (this.whiteLayout && this.state.prev === 0) {
                searchIconColor = "#373B4B";
            } else {
                searchIconColor = "#fff";
                className = "navbar-dark";
            }
        } else if (this.state.isTablet) {
            searchIconColor = "#fff";
            className = "navbar-dark";
        }

        if(this.state.searchSideDrawerOpen) {
            bottomNavigationClassName += ' d-none';
        }

        return (
            <div className="navbar-wrapper">
                <Navbar expand="lg" className={className} id="navbar">
                    <Container>
                        <Navbar.Brand>
                            <a href={ROUTES.home.path}
                               onClick={(event) => {
                                   event.preventDefault();
                                   navigate(ROUTES.home.path);
                                   return false;
                               }}>
                                <img
                                    data-src={ROUTES.home.icon}
                                    className="align-top lazyload navbar-image"
                                    alt={ROUTES.home.icon_alt}
                                    id="icon-color"
                                />
                                <img
                                    data-src={ROUTES.home.icon_white_text}
                                    className="align-top lazyload navbar-image"
                                    alt={ROUTES.home.icon_alt}
                                    id="icon-white-text"
                                />
                            </a>
                        </Navbar.Brand>
                        <Navbar.Collapse className="justify-content-end" id="navbarSupportedContent">
                            <Nav>
                                <a className="navbar-link margin-right-50" href={ROUTES.reviews.path}
                                   onClick={(event) => {
                                       event.preventDefault();
                                       navigate(ROUTES.reviews.path);
                                       return false;
                                   }}>
                                    {ROUTES.reviews.menu_title}
                                </a>
                            </Nav>
                            <Nav>
                                <a className="navbar-link margin-right-50" href={ROUTES.best.path}
                                   onClick={(event) => {
                                       event.preventDefault();
                                       navigate(ROUTES.best.path);
                                       return false;
                                   }}>
                                    {ROUTES.best.menu_title}
                                </a>
                            </Nav>
                            <Nav>
                                <a className="navbar-link margin-right-50" href={ROUTES.tutorials.path}
                                   onClick={(event) => {
                                       event.preventDefault();
                                       navigate(ROUTES.tutorials.path);
                                       return false;
                                   }}>
                                    {ROUTES.tutorials.menu_title}
                                </a>
                            </Nav>
                            <Nav>
                                <a className="navbar-link margin-right-50" href={ROUTES.discounts.path}
                                   onClick={(event) => {
                                       event.preventDefault();
                                       navigate(ROUTES.discounts.path);
                                       return false;
                                   }}>
                                    {ROUTES.discounts.menu_title}
                                </a>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                {!this.isSearch &&
                <StaticQuery
                    query={graphql` query SearchHomepageQuery { siteSearchIndex{index}} `}
                    render={data => (
                        <SearchBar
                            searchIconColor={ searchIconColor }
                            searchIndex={data.siteSearchIndex.index}
                            show={this.state.searchSideDrawerOpen}
                            isMobile={this.state.isMobile}
                            searchNavbarOpen={this.state.searchSideDrawerOpen}
                            isTablet={this.state.isTablet}
                        />
                    )}
                />
                }
                <div className={bottomNavigationClassName} id="bottom-navigation">
                    <div className={this.checkIfCurrentPage(ROUTES.discounts.path) ? 'bottom-navigation-active' : ''}>
                        <a className="bottom-navigation-link" href={ROUTES.discounts.path}
                           onClick={(event) => {
                               event.preventDefault();
                               navigate(ROUTES.discounts.path);
                               return false;
                           }}>
                            <svg className="bottom-navigation-icon-discounts">
                                <use xlinkHref="/images/nav_icon_discounts.svg#discounts"/>
                            </svg>
                            <p className="bottom-navigation-link-text">{ROUTES.discounts.menu_title}</p>
                        </a>
                    </div>
                    <div className={this.checkIfCurrentPage(ROUTES.tutorials.path) ? 'bottom-navigation-active' : ''}>
                        <a className="bottom-navigation-link" href={ROUTES.tutorials.path}
                           onClick={(event) => {
                               event.preventDefault();
                               navigate(ROUTES.tutorials.path);
                               return false;
                           }}>
                            <svg className="bottom-navigation-icon-tutorials">
                                <use xlinkHref="/images/nav_icon_tutorials.svg#tutorials"/>
                            </svg>
                            <p className="bottom-navigation-link-text">{ROUTES.tutorials.menu_title}</p>
                        </a>
                    </div>
                    <div className={this.checkIfCurrentPage(ROUTES.best.path) ? 'bottom-navigation-active' : ''}>
                        <a className="bottom-navigation-link" href={ROUTES.best.path}
                           onClick={(event) => {
                               event.preventDefault();
                               navigate(ROUTES.best.path);
                               return false;
                           }}>
                            <svg className="bottom-navigation-icon-best">
                                <use xlinkHref="/images/nav_icon_bestfor-white.svg#best"/>
                            </svg>
                            <p className="bottom-navigation-link-text">{ROUTES.best.menu_title}</p>
                        </a>
                    </div>
                    <div className={this.checkIfCurrentPage(ROUTES.reviews.path) ? 'bottom-navigation-active' : ''}>
                        <a className="bottom-navigation-link" href={ROUTES.reviews.path}
                           onClick={(event) => {
                               event.preventDefault();
                               navigate(ROUTES.reviews.path);
                               return false;
                           }}>
                            <svg className="bottom-navigation-icon-review">
                                <use xlinkHref="/images/nav_icon_review-white.svg#review"/>
                            </svg>
                            <p className="bottom-navigation-link-text">{ROUTES.reviews.menu_title}</p>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

Header.propTypes = {
    whiteLayout: PropTypes.bool.isRequired,
    isSearch: PropTypes.bool.isRequired,
}

export default Header;
