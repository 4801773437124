import React from "react";
import ROUTES from "../../configs/route";
import {Index} from "elasticlunr";
import Category from "../category/category.component";
import SearchIcon from "../../../static/icons/search";
import {Link} from "gatsby";
import SearchClass from "./SearchClass.component";
import PropTypes from "prop-types";
import Backdrop from "./Backdrop.component";

class SearchBar extends SearchClass {
    constructor(props) {
        super(props);

        this.state = {
            searchNavbarOpen: props.searchNavbarOpen,
            query: "",
            last_position: 0,
            results: [],
            articlesCount: 0,
            filteredResults: [],
        };

        this.isTablet = props.isTablet;
        this.searchBarClickEvent = this.searchBarClick.bind(this);
        this.searchBarCloseEvent = this.searchBarClose.bind(this);
    }

    componentDidMount() {
        let searchBarDesktop = document.getElementById("search-bar-desktop");

        if (searchBarDesktop) {
            searchBarDesktop.addEventListener("click", this.searchBarClickEvent);
        }
        document.body.addEventListener("click", this.searchBarCloseEvent);
    }

    componentWillUnmount() {
        document.getElementById("search-bar-desktop").removeEventListener("click", this.searchBarClickEvent);
        document.body.removeEventListener("click", this.searchBarCloseEvent);
    }

    searchBarClick(event) {
        this.setState({
            searchNavbarOpen: true,
        });

        let input = document.getElementById("search-input");
        if (input) {
            input.focus();
        }

        this.props.handleStateChange(this.state.searchNavbarOpen);
    }

    searchBarClose(event) {
        let searchBar = document.getElementById("search-bar-desktop"),
            target = event.target;

        if (searchBar !== target && (target.classList.contains("backdrop") || target.classList.contains("search-menu-bar-close"))) {
            this.setState({
                searchNavbarOpen: false,
                query: "",
                results: [],
                filteredResults: []
            });
            this.props.handleStateChange(this.state.searchNavbarOpen);
        }
    }

    getSearchPageLink() {
        return ROUTES.search_page.path + "?search="+ this.state.query;
    }

    goToSearchPage() {
        window.location = this.getSearchPageLink();
    }

    getOrCreateIndex() {
        return this.index ? this.index : Index.load(this.props.searchIndex);
    }

    disableBodyScroll() {
        if(this.isTablet) {
            console.log(this.state.searchNavbarOpen);
            let body = document.querySelector("body");
            if (this.state.searchNavbarOpen) {
                body.classList.add("overflow-hidden");
            } else {
                body.classList.remove("overflow-hidden");
            }
        }
    }

    backdropClickHandler = () => {
        this.setState({
            searchNavbarOpen : false
        });
        this.disableBodyScroll();
    };

    renderSearchIcon() {
        return (
            <span className="search-menu-bar-icon" aria-label="Search" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" tabIndex={0}>
                <SearchIcon className="search-menu-bar-icon" fill={this.props.searchIconColor} height={21} width={21}/>
            </span>
        );
    }

    render() {
        let searchDrawerClasses = ["search-side-drawer"];
        if (this.state.searchNavbarOpen) {
            searchDrawerClasses += [" open search-bar-expanded"];
        }

        return (
            <div>
                <Backdrop click={this.backdropClickHandler} className={this.state.searchNavbarOpen ? 'backdrop active': 'backdrop'}/>
                <div id="search-bar-desktop">
                    <div className={searchDrawerClasses}>
                        <div className="search-menu-bar">
                            <svg className="search-menu-bar-close d-none d-lg-block">
                                <use xlinkHref="/images/plus-dark.svg#plus-dark"/>
                            </svg>
                            <svg className="search-menu-bar-close d-lg-none">
                                <use xlinkHref="/images/arrow-right-white.svg#arrow"/>
                            </svg>
                            <input className="search-menu-bar-input"
                                   type="text"
                                   aria-label="search"
                                   onKeyPress={event => {
                                       if (event.key === "Enter") {
                                           this.goToSearchPage()
                                       }
                                   }}
                                   id="search-input"
                                   onChange={this.search.bind(this)}
                                   autoComplete="off"
                                   value={this.state.query}
                                   placeholder="Search for posts"
                            />
                        </div>
                        {!this.state.query &&
                            <span className="search-suggestion">
                                Tell me what you are looking for.
                            </span>
                        }
                        {this.state.query && this.state.filteredResults.edges && this.state.filteredResults.edges.length === 0 &&
                            <span className="search-suggestion">
                                Sorry, no match...
                            </span>
                        }
                        {this.state.filteredResults.edges && this.state.filteredResults.edges.length > 0 &&
                            <div className="search-menu-bar-results">
                                <Category data={this.state.filteredResults.edges} isSearch={true} limit={4}/>
                                <Link to={this.getSearchPageLink()} className="search-menu-bar-link">
                                    Show all results
                                </Link>
                            </div>
                        }
                    </div>
                    <button aria-label="Search" className="search-menu-bar-button">
                        {this.renderSearchIcon()}
                    </button>
                </div>
            </div>
        )
    }
}

SearchBar.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    isTablet: PropTypes.bool.isRequired,
    searchIconColor: PropTypes.string.isRequired,
    searchIndex: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
}

export default SearchBar;
