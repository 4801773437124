import React from "react";
import PropTypes from "prop-types";
import Post from "./post/post.component";

const Category = (props) => {
    let className = 'category-main-wrapper';
    if (props.isSearch) {
        className += ' search';
    }
    return (
        <div className={className}>
            {props.data &&
                props.data.map((item, index) => {
                    if (props.limit && props.limit >= index + 1) {
                        return (
                            <Post key={index} data={item}/>
                        )
                    } else if (!props.limit) {
                        return (
                            <Post key={index} data={item}/>
                        )
                    }
                })
            }
        </div>
    );
};

Category.propTypes = {
    data: PropTypes.array.isRequired,
    isSearch: PropTypes.bool,
    limit: PropTypes.number
};

export default Category
