import React, {Component} from "react";
import {reload_document_lazy_images} from "../../helpers";

const CONFIG = require("../../configs/main");
const isProduction = process.env.ENV === "production";

class SearchClass extends Component {
    constructor(props) {
        super(props);

        this.state = {
            query: '',
            last_position: 0,
            results: [],
            articlesCount: 0,
            filteredResults: [],
        };
    }

    search(event = null) {
        const query = event ? event.target.value : this.getQueryParams('search', window.location.href);
        this.index = this.getOrCreateIndex();
        let resultsArray = this.index.search(query, {expand: true}).map(({ref}) => this.index.documentStore.getDoc(ref));

        let results = this.filterArticles(resultsArray);
        let articleCount = results.edges.length;

        this.setState({
            query: query,
            last_position: 0,
            results: results,
            articlesCount: articleCount
        });
        this.addArticlesToArray('', results);
    }

    getQueryParams(params, url) {
        let href = url;
        let reg = new RegExp('[?&]' + params + '=([^&#]*)', 'i');
        let queryString = reg.exec(href);

        return queryString ? queryString[1] : null;
    };

    filterArticles(resultsArray) {
        let filteredArticles = {'edges': []};
        if (!isProduction) {
            resultsArray.forEach(page => {
                filteredArticles.edges.push({'node': page});
            });
        } else {
            resultsArray.forEach(page => {
                if (page.to_live === '1') {
                    filteredArticles.edges.push({'node': page});
                }
            });
        }

        return filteredArticles;
    }

    addArticlesToArray(event = null, results) {
        let currentArticles = [],
            lastPosition = this.state.last_position;

        if (event) {
            event.preventDefault();
            lastPosition = lastPosition + CONFIG.category_pagination_count;
            currentArticles = {'edges': this.state.results.edges.slice(0, lastPosition)};
            this.setState({
                last_position: lastPosition,
                filteredResults: currentArticles
            }, function () {
                reload_document_lazy_images();
            });
        } else {
            lastPosition = CONFIG.category_pagination_count;
        }

        if (results && results.edges) {
            currentArticles = {'edges': results.edges.slice(0, lastPosition)};
            this.setState({
                last_position: lastPosition,
                filteredResults: currentArticles
            }, function () {
                reload_document_lazy_images();
            });
        }
    }

    render() {
        return (
            <div></div>
        );
    }
}

export default SearchClass
