const React = require("react");

function reload_document_lazy_images () {
    document.querySelectorAll('img').forEach(img => {
        if (img.classList.contains('lazyloaded')) {
            img.classList.remove('lazyloaded');
            img.classList.add('lazyload');
        }
    });
}

function getImage(featuredMediaSrcSet, className, title) {
    let imageLink = (
        <img className={className + " lazyload"} data-src="https://media.emit.reviews/images/placeholder.png" alt={title}/>
    );

    if (featuredMediaSrcSet) {
        const featuredMediaSrcset = JSON.parse(featuredMediaSrcSet);
        if (featuredMediaSrcset.sizes && featuredMediaSrcset.file_url) {
            imageLink = (
                <img
                    data-sizes="auto"
                    data-src={ featuredMediaSrcset.file_url }
                    data-srcset={ featuredMediaSrcset.srcset }
                    className={className + " lazyload"}
                    alt={ title }
                />
            );
        }
    }

    return imageLink;
}

module.exports = {
    reload_document_lazy_images,
    getImage: getImage,
};
